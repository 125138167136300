import React from 'react';
import Layout from "../components/layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { ReactComponent as Pic404 } from '../images/404.svg'
import { ReactComponent as Pic404Bg } from '../images/404-bg.svg'

export default function NotFoundPage({location}) {
  return (
      <Layout>
        <GatsbySeo canonical={location.href} title="404" description="404"/>
        <div className="text-center py-36 relative">
            <Pic404 width={420} className="mx-auto" />
            <h1 className="text-2xl font-bold leading-none pb-4 mt-8">Page Not Found</h1>
            <a href="/" className="text-lg text-gray-dark underline">Take me home</a>
            <figure className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-[-1]"><Pic404Bg width={1185} height={232} /></figure>
        </div>
      </Layout>
  )
}
